var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.popupParam.multiple
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "설비 목록",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    data: _vm.grid.data,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    hideBottom: true,
                    editable: _vm.editable,
                    rowKey: "equipmentCd",
                    selection:
                      _vm.popupParam.noPlan === "Y" ? "multiple" : "none",
                  },
                  on: {
                    rowClick: _vm.rowClick,
                    callbackProxy: _vm.callbackProxy,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.popupParam.noPlan === "Y"
                            ? _c("c-btn", {
                                attrs: {
                                  label: "무계획결과 저장",
                                  icon: "save",
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.popupParam.noPlan === "Y"
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.popupParam.noPlan === "Y"
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { attrs: { title: "설비별 결과상세" } },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.popupParam.noPlan === "N"
                        ? _c("c-btn", {
                            attrs: {
                              editable: _vm.editable,
                              label: "설비별 점검결과 저장",
                              icon: "save",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "설비별 결과 기본정보", bgClass: "" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "사업장/점검부서",
                                  value:
                                    _vm.result.plantNm +
                                    "/" +
                                    _vm.result.deptNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "설비유형/설비코드/설비명",
                                  value:
                                    _vm.result.equipmentType +
                                    "/" +
                                    _vm.result.equipmentCd +
                                    "/" +
                                    _vm.result.equipmentNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검유형",
                                  value: _vm.result.safFacilityNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검자",
                                  value: _vm.result.chkUserNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검예정일",
                                  value: _vm.result.chkSchYmd,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  label: "점검완료일",
                                  name: "chkSchCompleYmd",
                                },
                                model: {
                                  value: _vm.result.chkSchCompleYmd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.result, "chkSchCompleYmd", $$v)
                                  },
                                  expression: "result.chkSchCompleYmd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-select", {
                                attrs: {
                                  comboItems: _vm.chkResultItems,
                                  editable: _vm.editable,
                                  type: "edit",
                                  itemText: "codeName",
                                  itemValue: "code",
                                  name: "chkResult",
                                  label: "점검결과",
                                },
                                model: {
                                  value: _vm.result.chkResult,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.result, "chkResult", $$v)
                                  },
                                  expression: "result.chkResult",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-select", {
                                attrs: {
                                  comboItems: _vm.chkRsltYnItems,
                                  editable: _vm.editable,
                                  type: "edit",
                                  itemText: "codeName",
                                  itemValue: "code",
                                  name: "chkRsltYn",
                                  label: "점검완료여부",
                                },
                                model: {
                                  value: _vm.result.chkRsltYn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.result, "chkRsltYn", $$v)
                                  },
                                  expression: "result.chkRsltYn",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "점검결과요약",
                                  name: "chkRsltSmry",
                                },
                                model: {
                                  value: _vm.result.chkRsltSmry,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.result, "chkRsltSmry", $$v)
                                  },
                                  expression: "result.chkRsltSmry",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "table",
                            attrs: {
                              title: "설비별 점검항목",
                              columns: _vm.grid2.columns,
                              gridHeight: _vm.grid2.height,
                              data: _vm.grid2.data,
                              filtering: false,
                              columnSetting: false,
                              usePaging: false,
                              hideBottom: true,
                              noDataLabel: "설비에 대한 점검항목이 없습니다.",
                              editable:
                                _vm.editable && !_vm.popupParam.disabled,
                              rowKey: "checkItemSeq",
                              selection: "multiple",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "추가",
                                            showLoading: false,
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addItem },
                                        })
                                      : _vm._e(),
                                    _vm.editable && _vm.grid2.data.length > 0
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            showLoading: false,
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeItem },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }